<template>
  <div class="app-container">
    <div class="text">
      <span>客服上班时间：11:00-22:00(工作日)</span>
      <span>请您长按识别二维码添加客服微信</span>
    </div>
    <div class="qrcode-box">
      <div class="qrcode">
        <img :src="oss2cdn(config.customServiceQrCodeUrl)" alt="">
      </div>
      <div class="tip">
        <span />
        <em>长按识别</em>
      </div>
    </div>
  </div>
</template>
<script>
import {Landing} from '@/network'
import {oss2cdn} from '@/utils'

const CONFIG = {
  bannerBgUrl: null,
  contentBgUrl: null,
  customServiceQrCodeUrl: null
}

export default {
  data() {
    return {
      config: CONFIG,
      oss2cdn
    }
  },
  async created() {
    // 页面配置信息
    const {data} = await Landing.getLandingPageConfig({
      id: this.$route.query.goodsId
    })
    try {
      this.config = JSON.parse(data.presentConfig) || CONFIG
    } catch (error) {
      console.warn(error)
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #f4f4f4;
}
.text {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
  span {
    width: auto;
    line-height: 23px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
  }
}
.qrcode-box {
  width: 272px;
  height: 261px;
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: 25px 0;
  .qrcode {
    width: 180px;
    height: 180px;
    border-radius: 5px;
    border: 1px solid #EAEAEA;
    box-sizing: border-box;
    padding: 6px;
    margin-bottom: 12px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
  .tip {
    width: auto;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      width: 20px;
      height: 20px;
      background-image: url('~@/assets/images/fingerprint-icon.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      margin-right: 5px;
    }
    em {
      width: auto;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2A5082;
      line-height: 20px;
      font-style: normal;
    }
  }
}
</style>
